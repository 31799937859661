<template>
  <merchants></merchants>
</template>

<script>
import Merchants from '../admin/Merchants.vue'
export default {
  components: {
    Merchants
  }
}
</script>